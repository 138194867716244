import React from "react";

import "./topnav.css";

import { Link } from "react-router-dom";

import Dropdown from "../dropdown/Dropdown";

import ThemeMenu from "../thememenu/ThemeMenu";

import notifications from "../../assets/JsonData/notification.json";

import user_image from "../../assets/images/logo.png";

import user_menu from "../../assets/JsonData/user_menus.json";
import { useDispatch } from "react-redux";
import { logoutAction } from "../../redux/actions/adminAction";
import { USER_LOGIN } from "../../util/setting";

const curr_user = {
    display_name: localStorage.getItem(USER_LOGIN),
    image: user_image,
};

const renderNotificationItem = (item, index) => (
    <div className="notification-item" key={index}>
        <i className={item.icon}></i>
        <span>{item.content}</span>
    </div>
);

const renderUserToggle = (user) => (
    <div className="topnav__right-user">
        <div className="topnav__right-user__image">
            <img src={user.image} alt="" />
        </div>
        <div className="topnav__right-user__name">{user.display_name}</div>
    </div>
);

const Topnav = () => {
    const dispatch = useDispatch();
    const renderUserMenu = (item, index) => (
        <Link
            to="/"
            key={index}
            onClick={() => {
                dispatch(logoutAction());
            }}
        >
            <div className="notification-item">
                <i className={item.icon}></i>
                <span>{item.content}</span>
            </div>
        </Link>
    );
    return (
        <div className="topnav">
            <div className="topnav__search">
                {/* <input type="text" placeholder="Search here..." />
                <i className="bx bx-search"></i> */}
            </div>
            <div className="topnav__right">
                <div className="topnav__right-item">
                    {/* dropdown here */}
                    <Dropdown
                        customToggle={() => renderUserToggle(curr_user)}
                        contentData={user_menu}
                        renderItems={(item, index) =>
                            renderUserMenu(item, index)
                        }
                    />
                </div>
                {/* <div className="topnav__right-item">
                    <Dropdown
                        icon="bx bx-bell"
                        badge="12"
                        contentData={notifications}
                        renderItems={(item, index) =>
                            renderNotificationItem(item, index)
                        }
                        renderFooter={() => <Link to="/">View All</Link>}
                    />
                </div> */}
                {/* <div className="topnav__right-item">
                    <ThemeMenu />
                </div> */}
            </div>
        </div>
    );
};

export default Topnav;
